import React from 'react';
import './Schedule.css';

const Schedule = (props) => {
    const { serial, datum, dan, Sufur, Juftar } = props.schedule;
    return (
        <div className='schedule'>
            <p>{serial} Ramazan</p>
            <p>{datum}</p>
            <p>{dan}</p>
            <p>{Sufur}</p>
            <p>{Juftar}</p>
        </div>
    );
};

export default Schedule;