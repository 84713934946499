import React from 'react';
import useData from '../../Hooks/useData';
import Schedule from '../Schedule/Schedule';
import './NazwatSection.css';

const NazwatSection = () => {
    const [scheduleData] = useData(); // Removed unused setScheduleData

    // Filter schedule data for Nazwat category
    const nazwat = scheduleData.filter((singleData) => singleData.category === "Nazwat");

    return (
        <div className='main-div'>
            <h2>10 Dana Nijaat</h2>
            <div className="headings">
                <h3>Ramazan</h3>
                <h3>Datum</h3>
                <h3>Dan</h3>
                <h3>Sufur</h3>
                <h3>Juftar</h3>
            </div>
            <div className="schedules">
                {/* Render each Nazwat schedule using the Schedule component */}
                {nazwat.map(schedule => <Schedule key={schedule.serial} schedule={schedule} />)}
            </div>
        </div>
    );
};

export default NazwatSection;