import React from 'react';
import useData from '../../Hooks/useData';
import Schedule from '../Schedule/Schedule';
import './RahmetSection.css';

const RahmetSection = () => {
    const [scheduleData] = useData(); // Removed unused setScheduleData

    // Filter schedule data for Rahmet category
    const Rahmet = scheduleData.filter((singleData) => singleData.category === "Rahmet");

    return (
        <div className='main-div'>
            <h2>10 Dana Rahmeta</h2>
            <div className="headings">
                <h3>Ramazan</h3>
                <h3>Datum</h3>
                <h3>Dan</h3>
                <h3>Sufur</h3>
                <h3>Juftar</h3>
            </div>
            <div className="schedules">
                {/* Render each Rahmet schedule using the Schedule component */}
                {Rahmet.map(schedule => <Schedule key={schedule.serial} schedule={schedule} />)}
            </div>
        </div>
    );
};

export default RahmetSection;
