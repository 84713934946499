import React, { useEffect, useState } from "react";
import './TopBanner.css';
import redDot from '../../img/2-red-dot.gif';
import { BsFillMoonStarsFill } from 'react-icons/bs';
import { RiErrorWarningFill } from 'react-icons/ri';

const TopBanner = () => {
    const [time, setTime] = useState('');
    const [date, setDate] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [sufur, setSufur] = useState('');
    const [izlazak, setIzlazak] = useState('');
    const [juftar, setJuftar] = useState('');

    const dayList = ["Nedjelja", "Ponedjeljak", "Utorak", "Srijeda", "Četvrtak", "Petak", "Subota"];
    const monthList = ["Januar", "Februar", "Mart", "April", "Maj", "Juni", "Juli", "August", "Septembar", "Oktobar", "Novembar", "Decembar"];

    useEffect(() => {
        const interval = setInterval(() => {
            const currentDate = new Date();
            const currentTime = currentDate.toLocaleTimeString();
            const currentDay = currentDate.getDay();
            const currentDateValue = currentDate.getDate();
            const currentMonthValue = currentDate.getMonth() + 1;
            const currentYearValue = currentDate.getFullYear();

            setTime(currentTime);
            setDate(currentDateValue);
            setMonth(monthList[currentMonthValue - 1]);
            setYear(currentYearValue);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const todaysDate = `${date} ${month} ${year}`;
        fetch('/data.json')
            .then(response => response.json())
            .then(data => {
                const todayData = data.find(item => item.datum === todaysDate);

                if (todayData) {
                    setSufur(todayData.Sufur);
                    setIzlazak(todayData.Izlazak);
                    setJuftar(todayData.Juftar);
                } else {
                    setSufur('N/A');
                    setIzlazak('N/A');
                    setJuftar('N/A');
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setSufur('Error');
                setIzlazak('Error');
                setJuftar('Error');
            });
    }, [date, month, year]);

    return (
        <div className='top-banner'>
            <div className="top-left">
                <div className="current-time-section">
                    <h4 className='current-time'>{time}</h4>
                    <img src={redDot} alt="" />
                </div>
                <h1>Ramazan 2024</h1>
                <h3>Vremena za Sufur i Juftar</h3>
                <p>"O vjernici! Propisuje vam se post, kao što je propisan onima prije vas, da biste se grijeha klonili." (Surah Al-Baqarah 2:183)</p>
            </div>
            <div className="top-right">
                <h1>Danas</h1>
                <div className="main">
                    <div className="today">
                        <h2><BsFillMoonStarsFill /></h2>
                        <h3>Ramazan</h3>
                        <p>{`${date} ${month} ${year}`}</p>
                        <p>{dayList[new Date().getDay()]}</p>
                    </div>

                    <div className="todays-schedule">
                        <p>Sufur - <span className="time">{sufur ? sufur : <RiErrorWarningFill />}</span></p>
                        <p><span className="">﷽</span></p>
                        <p>Juftar - <span className="time">{juftar ? juftar : <RiErrorWarningFill />}</span></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopBanner;
