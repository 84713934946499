// Remove the unused import
// import React from "react";
import { useState, useEffect } from "react";

const useData = () => {
    const [scheduleData, setScheduleData] = useState([]);
    useEffect(() => {
        fetch('data.json')
            .then(res => res.json())
            .then(data => setScheduleData(data));
    }, []);

    return [scheduleData]; // Removed setScheduleData from the return value
}

export default useData;
