import React from 'react';
import useData from '../../Hooks/useData';
import Schedule from '../Schedule/Schedule';
import './MagfiratSection.css';

const MagfiratSection = () => {
    const [scheduleData] = useData(); // Removed unused setScheduleData

    // Filter schedule data for Magfirat category
    const magfirat = scheduleData.filter((singleData) => singleData.category === "Magfirat");

    return (
        <div className='main-div'>
            <h2 className='section-title'>10 Dana Magfirat</h2>
            <div className="headings">
                <h3>Ramazan</h3>
                <h3>Datum</h3>
                <h3>Dan</h3>
                <h3>Sufur</h3>
                <h3>Juftar</h3>
            </div>
            <div className="schedules">
                {/* Render each Magfirat schedule using the Schedule component */}
                {magfirat.map(schedule => <Schedule key={schedule.serial} schedule={schedule} />)}
            </div>
        </div>
    );
};

export default MagfiratSection;
